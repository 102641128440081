
/* 姫路データの移植 例によってreadしかしないのでそのまま進める */

// メタデータ
export const rootUri = "https://teniss-marineblue.com/"
export const apiRoot = rootUri + "/api"
export const diaryApiRoot = "https://api.marineblue-g.com/api"
export const hvnUriRoot = "https://www.cityheaven.net/ibaraki/A0802/A080202/marineblue-tennis"

// ヘブンブログパーツ類
export const hvnShopId = "2510022512";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=10&fontcolor=4&btncolor=4&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "パリス土浦店",
    tel:        "029-846-6367",
    openTime:   "6:00~24:00",
    zipCode:    "300-0037",
    zipAddress: "茨城県 土浦市桜町 2-4-10",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.6562172066733!2d140.19744024054614!3d36.07748847257665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60226d4b72b62df7%3A0x145425874bc5138e!2z44CSMzAwLTAwMzcg6Iyo5Z-O55yM5Zyf5rWm5biC5qGc55S677yS5LiB55uu77yU4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1685097974762!5m2!1sja!2sjp",
}

export const enterenceImg = "/img/bnr-enterence.jpg"

export const priceChart = "/img/20240716_price.jpg"
export const priceChart0807 = "/img/20241015_price.jpg"

// 20240807 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart0807

    }
    return priceChart
}




export const areaName = "土浦"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",    path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",    path: "/schedule/", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人",      path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍",      path: "/casts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金",      path: "/system", blank: false, img: "/img/ico/i-price.png"},
        {name: "アクセス",  path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "動画",      path: hvnUriRoot + "/shopmovie", blank: true, img: "/img/ico/i-video.png"},
        {name: "写メ日記",  path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
        {name: "女性求人",  path: "https://kitakanto.qzin.jp/teniss/?v=official", blank: true, img: "/img/ico/i-rec-women.png",
         tagForced: true,  requireTag: "vanilla"},
        {name: "男性求人",  path: "https://mensheaven.jp/17/marineblue-tennis/?of=y", blank: true, img: "/img/ico/i-diary.png"},
        {name: "口コミ",  path: "https://www.cityheaven.net/ibaraki/A0802/A080202/marineblue-tennis/reviews/?of=y2", blank: true, img: "/img/ico/i-diary.png"},
        {name: "ネット予約",  path: "https://www.cityheaven.net/ibaraki/A0802/A080202/marineblue-tennis/A6ShopReservation/?of=y2", blank: true, img: "/img/ico/i-diary.png"},
    ];
export const slideArr = [
    {
        link: {
            blank: true,
            uri: "https://www.cityheaven.net/ibaraki/A0802/A080202/marineblue-tennis/shopevent/?ref_bnLS=1" },
        img: {
            alt: "エリアNO1 極嬢多数!! 必ずお気に入りが見つかるお店です!! 写真指名完全無料!! パリス", 
            imgPathSp: "https://api.marineblue-g.com/api/topbanner?t=teniss_marineblue", },
    },   
    {
     link: {
        blank: true,
        uri: "https://www.cityheaven.net/ibaraki/A0802/A080202/marineblue-tennis/diarylist/" },
     img: {
        alt: "パリス土浦店の写メ日記一覧", 
        imgPathSp: "/img/slides/s02.jpg", 
        imgPathPc: "", },
    },
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "パリス土浦店 団体様も歓迎 営業時間6:00~LAST", 
        imgPathSp: "/img/slides/s03.jpg", 
        imgPathPc: "", },
    },

];